<template>
    <div id="app">
      <router-view></router-view> <!-- Displays the routed components -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'App'
  };
  </script>
  

  